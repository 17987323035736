/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
  scroll-behavior: smooth;
}

body,
html {
  overflow-x: hidden;
  background: rgba(245, 245, 247, 0.75) !important;
  font-family: 'Lato';
}

p {
  font-weight: 400;
}

a {
  color: #243A73;
  text-decoration: none !important;
  transition: 0.5s all;
  font-family: 'Lato';
}

a:hover {
  color: #000000;
  text-decoration: none;
}

html,
body {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato';
}

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Italic.woff2') format('woff2'),
    url('../fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.woff2') format('woff2'),
    url('../fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-LightItalic.woff2') format('woff2'),
    url('../fonts/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-Hairline.woff2') format('woff2'),
    url('../fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.woff2') format('woff2'),
    url('../fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-HairlineItalic.woff2') format('woff2'),
    url('../fonts/Lato-HairlineItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
    url('../fonts/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff2') format('woff2'),
    url('../fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Black.woff2') format('woff2'),
    url('../fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BlackItalic.woff2') format('woff2'),
    url('../fonts/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

.container-small {
  max-width: 900px;
  margin: 0 auto;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
}

.container-fluid {
  max-width: 1300px;
  margin: 0 auto;
}

.header_main {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  background: rgba(245, 245, 247, 0.75);
  height: 88px;
  position: relative;
  display: flex;
  align-items: center;
}

.header_left {
  display: flex;
  align-items: center;
}

.header_menus ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.social_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header_left a {
  display: block;
  margin-right: 40px;
}

.social_box .social_ico {
  display: block;
  margin-right: 18px;
}

.header_left a img {
  display: block;
  max-width: 52px;
}

.languagee .form-select {
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 56px;
  font-weight: 400;
  font-size: 17px;
  box-shadow: unset;
}

.header_menus ul li a {
  color: rgba(13, 13, 13, 1);
  font-size: 17px;
  font-weight: 400;
  display: block;
  transition: 0.3s ease-in-out;
  text-align: center;
}

.header_menus ul li a.active {
  font-weight: 900;
  color: rgba(13, 13, 13, 1);
}

.header_menus ul li {
  margin: 0 34px;
}

.header_dropdown .btn {
  background-color: transparent;
  border: 0;
  box-shadow: unset !important;
  outline: 0 !important;
  padding: 0 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 !important;
}

.soonpart {
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
  background: rgba(245, 245, 247, 0.75);
  padding: 8px 0;
}

.soonpart_content p {
  margin: 0;
  font-weight: 400;
  font-size: 17px;
}

.Become_Partner {
  background: rgba(250, 147, 191, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  color: #e084ab !important;
  border-radius: 50px;
  font-weight: 700;
  font-size: 17px;
  width: 192px;
}

.Become_Partner img {
  margin-left: 10px;
  max-height: 16px;
}

.banner_part {
  padding: 100px 0 180px;
  position: relative;
}

.banner_part::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: url(../img/bg.svg);
  width: 940px;
  z-index: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center 25%;
  background-size: cover;
}

.banner_part .container {
  position: relative;
  z-index: 1;
}

.banner_content h2 {
  font-weight: 900;
  font-size: 80px;
  line-height: 86px;
  margin: 0 0 25px;
}

.banner_content p {
  color: rgba(77, 77, 77, 1);
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
  max-width: 290px;
}

.emailbox {
  margin: 50px 0 40px;
}

.emailbox .form-group .form-control {
  border: 1px solid rgba(13, 13, 13, 0.25);
  height: 60px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 400;
  padding: 0px 30px 0px 32px;
  box-shadow: unset !important;
}

.getstarted {
  background: rgba(98, 101, 232, 1);
  color: #fff !important;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  font-size: 20px;
  font-weight: 900;
  padding: 0 36px;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: -2px -2px 8px 0px rgba(16, 18, 111, 0.4) inset;
}

.download_btn a {
  margin-right: 30px;
  display: inline-block;
}

.banner_img img {
  max-width: 85%;
  margin-bottom: -65px;
}

.footer_main {
  background: rgba(13, 13, 13, 1);
  padding: 96px 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.footer_about .logo {
  display: block;
  margin-bottom: 50px;
}

.footer_about .logo img {
  max-width: 215px;
}

.footer_btn {
  background-color: rgba(98, 101, 232, 1);
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 0 46px;
}

.footer_menus h2 {
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  line-height: 14px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
}

.footer_newsletter h2 {
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  line-height: 14px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
}

.footer_menus ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_menus ul li a {
  color: #fff ! IMPORTANT;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  transition: 0.3s all;
}

.footer_menus ul li a:hover {
  transform: translateX(5px);
}

.footer_menus ul li {
  margin-bottom: 22px;
}

.newsletter_box .form-control {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-radius: 50px;
  height: 42px;
  box-shadow: unset;
  padding: 0px 0px 0px 24px;
  color: rgba(255, 255, 255, 1);
}

.newsletter_box button {
  background-color: #fff;
  border: 0;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 900;
  color: rgba(13, 13, 13, 1);
  height: 42px;
  padding: 0 25px;
  position: absolute;
  right: 0;
  top: 0;
}

.appdownload {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}

.appdownload a img {
  max-width: 100%;
}

.appdownload a {
  width: 44%;
}

.copyright {
  background: rgba(13, 13, 13, 1);
  padding: 30px 0;
}

.copyright_data {
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.copyright_links,
.copyright_links a {
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.social_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social_footer a {
  margin: 0 8px;
}

.social_footer a img {
  transition: 0.3s ease-in-out;
}

.social_footer a:hover img {
  transform: translateY(-5px);
}

.copyright_select .form-select {
  border-radius: 50px;
  background-color: transparent;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  border: 2px solid #fff;
  padding: 0 24px 0 24px;
  height: 48px;
  box-shadow: unset;
  background-image: url(../img/expand_more.png);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: 130px center;
}

.captain_slider .captainslider_box.slick-slide.slick-current.slick-active.slick-center {
  transform: scale(1);
}

.captainslider_box {
  transform: scale(0.9);
  height: 500px;
  overflow: hidden;
  border-radius: 30px;
  transition: 0.5s all;
  position: relative;
}

.captainslider_box::after {
  position: absolute;
  content: '';
  bottom: -80px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 84.9%);
  height: 100%;
  width: 100%;
}

.captainslider_box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}


.captain_slider {
  padding: 50px 0 80px;
}


.captainslider_content {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 1;
}

.captainslider_content h2 {
  font-weight: 700;
  font-size: 44px;
  line-height: 55px;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
}

.captainslider_content p {
  font-size: 27px;
  font-weight: 400;
  color: #fff;
  margin: 0;
}


.commanservices {
  padding: 60px 0;
}

.comman_head h2 {
  color: rgba(13, 13, 13, 1);
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  line-height: 50px;
}

.comman_head {
  margin-bottom: 60px;
}

.commanservices_box {
  text-align: center;
  padding: 0 40px;
}

.commanservices_box img {
  max-height: 81px;
}

.commanservices_box h3 {
  color: rgba(13, 13, 13, 1);
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  margin: 30px 0 30px;
}

.commanservices_box2 h3 {
  color: #6265E8;
  font-size: 24px;
  font-weight: 700;
  line-height: 24px;
  margin: 30px 0 30px;
  font-family: 'Lato' !important;
}

.commanservices_box p {
  color: rgba(13, 13, 13, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.commanservices_box2 p {
  color: rgba(13, 13, 13, 1);
  font-size: 17px;
  font-weight: 400;
  line-height: 24px;
  font-family: 'Lato' !important;
}

.captain_slider .slick-next.slick-arrow {
  background-image: url(../img/expand_circle_right.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: 48%;
  z-index: 1;
  transform: translateY(-50%);
  right: 20px;
  background-color: white;
}

.captain_slider .slick-prev.slick-arrow {
  background-image: url(../img/expand_circle_right.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: 48%;
  z-index: 1;
  transform: scalex(-1) translateY(-50%);
  left: 20px;
  background-color: white;
}

.howitworkss_box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  margin: 0px 0 200px;
  position: relative;
  align-items: center;
  height: 720px;
  background-size: cover;
}

.howitworkss {
  padding: 100px 0 180px;
}

.howitworkss .container-fluid .howitworkss_box:last-child {
  margin-bottom: 0;
}

.howitworkss .container-fluid .howitworkss_box:nth-child(even) .howitworkss_content {
  padding: 0 100px 0 0;
}

.howitworkss_content span {
  font-size: 27px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  display: block;
}

.howitworkss_content h2 {
  font-size: 50px;
  font-weight: 900;
  line-height: 62px;
  color: #fff;
  display: block;
  margin: 20px 0 20px;
}

.howitworkss_content h2 strong {
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.howitworkss_content p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 34px;
  margin: 0;
}

.howitworkss_content {
  padding: 0 0 0 100px;
}

.howitworkss_img {
  margin: -60px 0;
}

.howitworkss_img img {
  max-width: 100%;
  /* box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25); */
  border-radius: 70px;
}

.downloadapotholis {
  padding: 100px 0;
}

.downloadapotholis_img img {
  max-width: 100%;
}

.apotholis_content h2 {
  font-weight: 900;
  font-size: 50px;
  line-height: 62px;
  color: rgba(0, 0, 0, 0.75);
  margin: 0 0 30px;
}

.apotholis_content h2 span {
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.apotholis_content p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: rgba(26, 15, 28, 1);
}

.download_atho {
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  border-radius: 12px;
  background-color: rgba(98, 101, 232, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  padding: 0 40px;
}

.commanhead1 span {
  font-size: 20px;
  font-weight: 400;
  color: rgba(26, 15, 28, 1);
  display: block;
}

.commanhead1 h2 {
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 63px;
  line-height: 75px;
  margin: 0;
  text-transform: uppercase;
}

.commanhead1 {
  margin-bottom: 60px;
}

.map_box img {
  max-width: 100%;
  width: 100%;
}

.app_screen {
  padding: 100px 0 100px;
}

.check_img {
  max-width: 35px;
}

.commaper_with tr th {
  text-align: center;
  border: 0;
  font-size: 20px;
  font-weight: 700;
  color: rgba(13, 13, 13, 1);
  padding: 24px 20px;
}

.commaper_with tr td {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: rgba(13, 13, 13, 1);
  padding: 24px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  border: 0;
}

.commaper_with .table tbody tr td:first-child {
  text-align: left;
}

.table>:not(:first-child) {
  border-top: 0;
}

.commaper_with tr td p {
  font-size: 16px;
  margin: 20px 0 0;
  max-width: 280px;
}

.appscreen_img {
  transform: scale(0.9);
  transition: 0.3s all;
  opacity: 0.2;
  text-align: center;
}

.app_screen_slider .appscreen_img.slick-slide.slick-current.slick-active.slick-center {
  transform: scale(1);
  opacity: 1;
}

.commaper_with {
  margin-top: 60px;
}

.appscreen_img img {
  height: 486px;
  margin: 0 auto;
}

.settingss {
  text-align: center;
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  color: rgba(13, 13, 13, 1);
  height: 48px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  padding: 0 22px;
  margin-bottom: 40px;
}

.app_screen_slider .slick-prev.slick-arrow {
  background-image: url(../img/slider_left.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: -65px;
  z-index: 1;
  left: 38%;
}

.app_screen_slider .slick-next.slick-arrow {
  background-image: url(../img/slider_right.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: -65px;
  z-index: 1;
  right: 38%;
}

.cumminity_box {
  height: 490px;
  position: relative;
  display: flex;
  align-items: center;
}

.cumminity_slider {
  background-color: rgba(98, 101, 232, 1);
  padding: 0 100px 0 30px;
}

.cumminity_box span {
  font-weight: 700;
  color: #fff;
  font-size: 32px;
  display: block;
  line-height: 30px;
}

.cumminity_box h2 {
  font-weight: 700;
  font-size: 42px;
  color: #fff;
  line-height: 52px;
  margin: 26px 0 20px;
}

.cumminity_box p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.cumminity_slider .slick-prev.slick-arrow {
  display: none !important;
}


.cumminity_slider .slick-next.slick-arrow {
  background-image: url(../img/arrow_forward_ios.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 39px;
  width: 25px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: 50%;
  z-index: 1;
  right: 0px;
  background-color: transparent;
  transform: translateY(-50%);
  display: none !important;
}

.cumminity_img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 490px;
}

.get_app h2 span {
  color: rgba(250, 147, 191, 1);
  background: unset;
  -webkit-text-fill-color: unset;
}

.get_app .download_atho {
  background-color: rgba(250, 147, 191, 1);
}

.download_athoborder {
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  border-radius: 12px;
  border: 2px solid rgba(250, 147, 191, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgba(250, 147, 191, 1) !important;
  padding: 0 40px;
  background-color: #FFFFFF;
}

.allview .nav-tabs {
  border-radius: 15px;
  justify-content: center;
  display: inline-flex;
  border: 0;
  background: rgba(118, 118, 128, 0.12);
  height: 48px;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 80px;
}

.allview .nav-tabs .nav-link {
  width: 56px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  height: 38px;
  border: 0 !important;
  box-shadow: unset;
  border-radius: 10px;
  margin: 0;
}

.allview .nav-tabs .nav-link img {
  max-width: 56px;
}

.allview .nav-tabs .nav-link.active {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  background-color: #fff;
}

.allview {
  padding: 100px 0;
}

.Partner_banner {
  position: relative;
  padding: 100px 0 320px;
}

.Partnerbanner_content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.Partnerbanner_content h1 {
  font-size: 80px;
  font-weight: 900;
  line-height: 88px;
  margin-bottom: 20px;
}

.Partnerbanner_content h1 span {
  color: rgba(250, 147, 191, 1);
}

.Partnerbanner_content p {
  font-size: 20px;
  font-weight: 400;
  color: rgba(13, 13, 13, 1);
  margin: 0;
  line-height: 32px;
}

.Partnerbanner_content .getstarted {
  box-shadow: -2px -2px 8px 0px rgba(135, 0, 57, 0.4) inset;
  background: linear-gradient(93.53deg, #FA93BF -29.92%, #D5538F 104.09%);
  font-size: 16px;
  padding: 0 26px;
  height: 50px;
}

.Partnerbanner_content .emailbox .form-group .form-control {
  border: 1px solid rgba(13, 13, 13, 0.25);
  height: 50px;
  font-size: 16px;
  padding: 0px 30px 1px 26px;
  line-height: normal;
}

.cut_img_1 {
  position: absolute;
  top: 130px;
  left: 0;
  max-width: 100%;
}

.cut_img_2 {
  position: absolute;
  top: -80px;
  right: 0px;
  max-width: 100%;
}

/* .Early_access {
  display: inline-flex;
  align-items: center;
  justify-content: center; 
  background: rgba(255, 255, 255, 1);
  border-radius: 50px;
  height: 42px;
  width: 128px; 
  overflow: hidden;
  position: relative;
}
.Early_access::after{
  position: absolute;
  content: '';
  border: 2px solid transparent;
  border-image: linear-gradient(77.65deg, #FA93BF 1.62%, #6265E8 54.14%, #10D6E3 98.88%);
  border-image-slice: 1; 
  width: 100%;
  height: 100%;
}
.Early_access span{
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 900; 
} */
.Early_access {
  margin-bottom: 25px;
}

.Partner_banner::after {
  position: absolute;
  content: '';
  background-image: url(../img/square.png);
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;
}

.partnermain {
  position: relative;
  z-index: 1;
  margin-top: -180px !important;
  margin-bottom: 130px;
}

.partner_boxes {
  box-shadow: 0px 12px 32px 0px rgba(250, 147, 191, 0.25);
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.partner_uperheding {
  font-weight: 700;
  font-size: 16px;
  color: rgba(13, 13, 13, 1);
  line-height: 12px;
}

.partner_uperheding {
  padding: 25px 0 55px;
  text-align: center;
}

.full_box {
  height: 282px;
}

.track_box {
  height: 133px;
}

.overview_img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 100%;
}

.small_boxess {
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small_head {
  font-weight: 700;
  font-size: 16px;
  color: rgba(13, 13, 13, 1);
  line-height: 12px;
  text-align: center;
}

.small_icons {
  margin-bottom: 15px;
  max-width: 50px;
}

.bussiness_report {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.reviewimg {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.calender_img {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.partneradjust {
  margin-top: -148px !important;
}

.mobile_menuss {
  display: none;
}

.mobile_menuss .btn {
  background: transparent;
  border: 0;
  padding: 0;
  box-shadow: unset;
}

.mobile_menuss .btn img {
  max-width: 31px;
}

.captainslider_box video {
  height: 100%;
  width: 100%;
}


@media (max-width:1279px) {
  .header_menus ul li {
    margin: 0 24px;
  }

  .container-fluid {
    max-width: 1160px;
  }
}

@media (max-width:1199px) {
  .container-fluid {
    max-width: 96%;
  }

  .header_left a {
    margin-right: 30px;
  }

  .header_menus ul li a {
    font-size: 16px;
  }

  .header_menus ul li {
    margin: 0 20px;
  }

  .header_left a img {
    max-width: 42px;
  }

  .social_box .social_ico {
    margin-right: 14px;
  }

  .social_box .social_ico img {
    max-width: 28px;
  }

  .header_dropdown .btn {
    height: 28px;
    width: 28px;
  }

  .header_main {
    height: 70px;
  }

  .soonpart_content p {
    font-size: 16px;
  }

  .Become_Partner {
    font-size: 16px;
    width: 182px;
  }

  .container {
    max-width: 95%;
    margin: 0 auto;
  }

  .banner_content h2 {
    font-size: 60px;
    line-height: 66px;
    margin: 0px 0 22px;
  }

  .banner_content p {
    font-size: 18px;
    line-height: 28px;
    max-width: 290px;
  }

  .emailbox {
    margin: 40px 0 30px;
  }

  .emailbox .form-group .form-control {
    height: 54px;
    font-size: 16px;
    padding: 0px 30px 0px 28px;
  }

  .getstarted {
    height: 54px;
    border-radius: 60px;
    font-size: 18px;
    font-weight: 900;
    padding: 0 28px;
  }

  .banner_img img {
    max-width: 80%;
    margin-bottom: -45px;
  }

  .banner_part {
    padding: 70px 0 140px;
  }

  .banner_part::after {
    width: 700px;
  }

  .captainslider_box {
    height: 400px;
    border-radius: 20px;
  }

  .captainslider_content h2 {
    font-size: 36px;
    line-height: 44px;
  }

  .captainslider_content p {
    font-size: 22px;
  }

  .captainslider_content {
    bottom: 30px;
    left: 30px;
  }

  .captain_slider {
    padding: 30px 0 60px;
  }

  .commanservices {
    padding: 50px 0;
  }

  .comman_head h2 {
    font-size: 38px;
  }

  .comman_head {
    margin-bottom: 50px;
  }

  .commanservices_box img {
    max-height: 66px;
  }

  .commanservices_box {
    padding: 0 30px;
  }

  .commanservices_box p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .commanservices_box h3 {
    font-size: 28px;
    line-height: 20px;
    margin: 25px 0 25px;
  }

  .howitworkss_content {
    padding: 0 0 0 60px;
  }

  .howitworkss_content span {
    font-size: 22px;
    line-height: 19px;
  }

  .howitworkss_content h2 {
    font-size: 38px;
    line-height: 51px;
    margin: 14px 0 14px;
  }

  .howitworkss_content p {
    font-size: 18px;
    line-height: 32px;
    margin: 0;
  }

  .howitworkss_box {
    border-radius: 30px;
    margin: 0px 0 140px;
    height: 600px;
  }

  .howitworkss_img img {
    max-width: 70%;
    border-radius: 50px;
  }

  .howitworkss {
    padding: 100px 0 130px;
  }

  .cumminity_img img {
    max-width: 80%;
  }

  .cumminity_box {
    height: 440px;
  }

  .cumminity_box span {
    font-size: 24px;
    line-height: 20px;
  }

  .cumminity_box h2 {
    font-size: 38px;
    line-height: 52px;
    margin: 20px 0 20px;
  }

  .cumminity_box p {
    font-size: 18px;
    margin: 0;
  }

  .cumminity_img {
    height: 440px;
  }

  .appscreen_img img {
    height: 380px;
    max-width: 100%;
  }

  .commanhead1 span {
    font-size: 18px;
  }

  .commanhead1 h2 {
    font-size: 50px;
    line-height: 65px;
  }

  .commanhead1 {
    margin-bottom: 40px;
  }

  .apotholis_content h2 {
    font-size: 40px;
    line-height: 54px;
    margin: 0 0 18px;
  }

  .apotholis_content p {
    font-size: 18px;
    line-height: 30px;
  }

  .download_atho,
  .download_athoborder {
    font-size: 16px;
    height: 54px;
    padding: 0 20px;
  }

  .footer_main {
    padding: 70px 0;
  }

  .footer_about .logo {
    margin-bottom: 30px;
  }

  .footer_about .logo img {
    max-width: 190px;
  }

  .footer_btn {
    font-size: 16px;
    height: 46px;
    padding: 0 40px;
  }

  .howitworkss .container-fluid .howitworkss_box:nth-child(even) .howitworkss_content {
    padding: 0 70px 0 0;
  }

  .settingss {
    font-size: 18px;
    height: 46px;
  }

  .Partnerbanner_content h1 {
    font-size: 60px;
    font-weight: 900;
    line-height: 68px;
    margin-bottom: 16px;
  }

  .Partnerbanner_content p {
    font-size: 18px;
  }

  .Partner_banner {
    padding: 70px 0 290px;
  }

  .cut_img_1 {
    top: 60px;
  }

  .cut_img_2 {
    top: -150px;
  }

  .partnermain {
    margin-top: -160px;
    margin-bottom: 90px;
  }

  .allview {
    padding: 80px 0;
  }

  .allview .nav-tabs {
    margin-bottom: 50px;
  }
}

@media (max-width:1023px) {
  .container-fluid {
    max-width: 98%;
  }

  .container {
    max-width: 98%;
  }

  .container-small {
    max-width: 92%;
  }

  .banner_content h2 {
    font-size: 48px;
    line-height: 56px;
    margin: 0px 0 20px;
  }

  .banner_content p {
    font-size: 17px;
    line-height: 27px;
  }

  .emailbox .form-group .form-control {
    height: 48px;
    font-size: 15px;
    padding: 0px 30px 0px 22px;
  }

  .emailbox {
    margin: 25px 0 25px;
  }

  .getstarted {
    height: 48px;
    border-radius: 60px;
    font-size: 14px;
    font-weight: 800;
    padding: 0 22px;
  }

  .download_btn {
    display: flex;
  }

  .download_btn a {
    margin-right: 18px;
  }

  .download_btn a img {
    max-height: 40px;
  }

  .banner_img img {
    max-width: 80%;
    margin-bottom: -25px;
  }

  .banner_part {
    padding: 60px 0 80px;
  }

  .banner_part::after {
    width: 550px;
  }

  .captainslider_box {
    height: 300px;
    border-radius: 14px;
  }

  .captainslider_content h2 {
    font-size: 30px;
    line-height: 38px;
  }

  .captainslider_content p {
    font-size: 18px;
  }

  .captainslider_content {
    bottom: 20px;
    left: 20px;
  }

  .captain_slider {
    padding: 30px 0 50px;
  }

  .comman_head h2 {
    font-size: 30px;
  }

  .commanservices_box {
    padding: 0;
  }

  .commanservices_box img {
    max-height: 52px;
  }

  .commanservices_box h3 {
    font-size: 22px;
    line-height: 18px;
    margin: 20px 0 20px;
  }

  .howitworkss {
    padding: 60px 0 100px;
  }

  .howitworkss_content h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 12px 0 12px;
  }

  .howitworkss_content span {
    font-size: 18px;
    line-height: 16px;
  }

  .howitworkss_content p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  }

  .howitworkss_content {
    padding: 0 0 0 30px;
  }

  .howitworkss_box {
    border-radius: 20px;
    margin: 0px 0 100px;
    height: 450px;
  }

  .howitworkss_img img {
    max-width: 70%;
    border-radius: 40px;
  }

  .cumminity_box h2 {
    font-size: 30px;
    line-height: 42px;
    margin: 15px 0 15px;
  }

  .cumminity_box span {
    font-size: 20px;
    line-height: 13px;
  }

  .cumminity_box p {
    font-size: 16px;
    margin: 0;
  }

  .cumminity_slider .slick-next.slick-arrow {
    height: 29px;
    width: 15px;
    top: 50%;
    right: 20px;
    background-size: 15px;
  }

  .cumminity_box,
  .cumminity_img {
    height: 340px;
  }

  .cumminity_slider {
    padding: 0 70px 0 30px;
  }

  .app_screen {
    padding: 70px 0 70px;
  }

  .settingss {
    font-size: 16px;
    height: 43px;
  }

  .app_screen_slider .slick-next.slick-arrow {
    top: -81px;
    right: 36%;
  }

  .app_screen_slider .slick-prev.slick-arrow {
    top: -81px;
    left: 36%;
  }

  .appscreen_img img {
    height: 400px;
    max-width: 100%;
  }

  .commaper_with {
    margin-top: 40px;
  }

  .commaper_with tr th,
  .commaper_with tr td {
    font-size: 16px;
    padding: 14px 10px;
  }

  .check_img {
    max-width: 26px;
  }

  .commanhead1 span {
    font-size: 16px;
  }

  .commanhead1 h2 {
    font-size: 38px;
    line-height: 55px;
  }

  .commanhead1 {
    margin-bottom: 30px;
  }

  .downloadapotholis {
    padding: 60px 0;
  }

  .apotholis_content h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 10px;
  }

  .apotholis_content p {
    font-size: 16px;
    line-height: 28px;
  }

  .download_atho,
  .download_athoborder {
    font-size: 14px;
    height: 46px;
    padding: 0 12px;
  }

  .footer_main {
    padding: 50px 0;
  }

  .footer_about {
    text-align: center;
  }

  .footer_menus {
    text-align: center;
  }

  .footer_menus h2,
  .footer_newsletter h2 {
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.2px;
    margin-bottom: 30px;
  }

  .footer_main .container>.row,
  .copyright .container>.row {
    justify-content: center;
  }

  .appdownload a {
    width: 48%;
  }

  .appdownload {
    margin-top: 28px;
  }

  .footer_newsletter {
    text-align: center;
  }

  .footer_menus ul li {
    margin-bottom: 16px;
  }

  .social_box {
    display: none;
  }

  .mobile_menuss {
    display: block;
  }

  .header_menus ul {
    flex-wrap: wrap;
  }

  .header_menus ul li {
    margin: 0 0 20px;
    width: 100%;
    text-align: left;
  }

  .header_menus ul li a {
    font-size: 18px;
    text-align: left;
  }

  .mobile_menuss .social_box {
    display: flex;
    justify-content: center;
  }

  .Early_access img {
    max-width: 110px;
  }

  .Early_access {
    margin-bottom: 20px;
  }

  .Partnerbanner_content h1 {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 14px;
  }

  .Partnerbanner_content p {
    font-size: 16px;
    line-height: 26px;
  }

  .cut_img_1 {
    top: 30px;
    max-width: 160px;
  }

  .cut_img_2 {
    top: -130px;
    max-width: 230px;
  }

  .Partner_banner {
    padding: 50px 0 170px;
  }

  .small_head {
    font-size: 15px;
    line-height: 21px;
  }

  .small_icons {
    margin-bottom: 10px;
    max-width: 40px;
  }

  .full_box {
    height: 252px;
  }

  .small_boxess,
  .track_box {
    height: 116px;
  }

  .partner_uperheding {
    padding: 20px 0 35px;
    text-align: center;
    font-size: 15px;
  }

  .partneradjust {
    margin-top: -134px;
  }

  .partnermain {
    margin-top: -90px;
    margin-bottom: 30px;
  }

  .allview {
    padding: 60px 0;
  }

  .header_main {
    z-index: 999;
  }
}

@media (max-width:767px) {
  .header_left {
    justify-content: space-between;
  }

  .languagee .form-select {
    font-weight: 600;
    font-size: 15px;
  }

  .header_menus ul li a {
    font-size: 16px;
    text-align: left;
  }

  .header_menus ul li {
    margin: 0px 0 16px;
  }

  .header_main {
    height: 60px;
  }

  .soonpart {
    padding: 10px 0;
  }

  .Become_Partner {
    font-size: 14px;
    width: 162px;
    height: 34px;
  }

  .Become_Partner img {
    margin-left: 6px;
    max-height: 12px;
    margin-bottom: -2px;
  }

  .banner_img img {
    max-width: 100%;
    margin-bottom: 0;
  }

  .banner_img {
    margin-bottom: 30px;
  }

  .banner_content {
    text-align: center;
  }

  .banner_content h2 {
    font-size: 36px;
    line-height: 44px;
    margin: 0px 0 15px;
  }

  .banner_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .download_btn {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .download_btn a {
    margin: 0 5px;
  }

  .banner_part::after {
    display: none;
  }

  .banner_part {
    padding: 50px 0 40px;
  }

  .captain_slider {
    padding: 20px 0 20px;
  }

  .commanservices {
    padding: 40px 0;
  }

  .captainslider_box {
    height: 240px;
    border-radius: 10px;
  }

  .captainslider_content {
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }

  .captainslider_content p {
    font-size: 16px;
  }

  .captainslider_content h2 {
    font-size: 24px;
    line-height: 31px;
  }

  .comman_head h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .comman_head {
    margin-bottom: 30px;
  }

  .commanservices_box {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .commanservices_box h3 {
    font-size: 18px;
    line-height: 16px;
    margin: 14px 0 14px;
  }

  .commanservices_box img {
    max-height: 40px;
  }

  .commanservices_box p {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
  }

  .howitworkss {
    padding: 0 0 40px;
  }

  .howitworkss_content h2 {
    font-size: 26px;
    line-height: 36px;
    margin: 10px 0 10px;
  }

  .howitworkss_content p {
    font-size: 15px;
    line-height: 26px;
    margin: 0;
  }

  .howitworkss_content span {
    font-size: 16px;
    line-height: 14px;
  }

  .howitworkss_content {
    padding: 0 20px !important;
    text-align: center;
  }

  .howitworkss_box {
    margin: 0px 0 30px;
    height: auto;
    padding: 40px 0 30px;
    border-radius: 20px;
    background-position: center;
  }

  .howitworkss_img {
    margin: 0 0 30px;
  }

  .order-changed {
    order: -1;
  }

  .howitworkss_img img {
    max-width: 90%;
    border-radius: 40px;
  }

  .cumminity_box,
  .cumminity_img {
    height: auto;
    padding: 40px 0;
  }

  .cumminity_box span {
    font-size: 16px;
    line-height: 13px;
  }

  .cumminity_box h2 {
    font-size: 26px;
    line-height: 34px;
    margin: 10px 0 10px;
  }

  .cumminity_box p {
    font-size: 15px;
    margin: 0;
  }

  .cumminity_slider {
    padding: 0 50px 0 30px;
  }

  .app_screen {
    padding: 50px 0 50px;
  }

  .settingss {
    font-size: 14px;
    height: 36px;
  }

  .app_screen_slider .slick-prev.slick-arrow {
    top: -60px;
    left: 15%;
    height: 30px;
    width: 30px;
  }

  .app_screen_slider .slick-next.slick-arrow {
    top: -60px;
    right: 15%;
    height: 30px;
    width: 30px;
  }

  .commaper_with tr th,
  .commaper_with tr td {
    font-size: 14px;
    padding: 12px 8px;
    white-space: nowrap;
  }

  .commaper_with tr td p {
    font-size: 15px;
    margin: 10px 0 0;
    max-width: 200px;
    white-space: break-spaces;
  }

  .check_img {
    max-width: 20px;
  }

  .commanhead1 span {
    font-size: 14px;
  }

  .commanhead1 h2 {
    font-size: 28px;
    line-height: 45px;
  }

  .downloadapotholis {
    padding: 50px 0;
  }

  .apotholis_content h2 {
    font-size: 26px;
    line-height: 34px;
    margin: 0 0 10px;
  }

  .apotholis_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .download_atho {
    font-size: 15px;
    height: 46px;
    padding: 0 20px;
  }

  .appscreen_img {
    transform: scale(1);
  }

  .appscreen_img img {
    max-width: 100%;
    height: auto;
  }

  .footer_about .logo {
    margin-bottom: 20px;
  }

  .footer_btn {
    font-size: 15px;
    height: 38px;
    padding: 0 24px;
  }

  .footer_about {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_menus h2,
  .footer_newsletter h2 {
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
  }

  .copyright .container>.row {
    margin: 0;
  }

  .footer_main {
    padding: 30px 0;
  }

  .social_footer img {
    max-width: 30px;
  }

  .copyright_select .form-select {
    font-size: 15px;
    border: 1px solid #fff;
    padding: 0 14px 0 24px;
    height: 42px;
    background-size: 12px;
    background-position: 230px center;
  }

  .copyright_data,
  .copyright_links,
  .copyright_links a {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }

  .cut_img_1 {
    top: 10px;
    max-width: 50px;
  }

  .cut_img_2 {
    top: -50px;
    max-width: 90px;
  }

  .Early_access img {
    max-width: 100px;
  }

  .Early_access {
    margin-bottom: 15px;
  }

  .Partnerbanner_content h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 11px;
  }

  .Partnerbanner_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .Partner_banner {
    padding: 50px 0 50px;
  }

  .partnermain {
    margin: 0 0 0px;
  }

  .partneradjust {
    margin-top: 0;
  }

  .track_box {
    height: 120px;
  }

  .allview {
    padding: 30px 0;
  }

  .allview .nav-tabs {
    margin-bottom: 30px;
  }

  .small_boxess {
    height: auto;
    padding: 20px 20px;
  }

  .get_app .row .col-md-6:first-child {
    order: 1;
    margin-top: 15px;
  }

  .apotholis_content p br {
    display: none;
  }

  .get_app .download_atho {
    display: flex;
    margin-bottom: 12px;
  }

  .download_athoborder {
    display: flex;
    margin: 0 !important;
  }
}

/* Add this CSS to your stylesheet or a <style> block in your component */
@keyframes jump {
  0% {
    transform: translateY(0);
  }

  20% {
    transform: translateY(-10px);
  }

  40% {
    transform: translateY(0);
  }

  60% {
    transform: translateY(-5px);
  }

  80% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(0);
  }
}

.errorText {
  display: inline-block;
  animation: jump 0.5s ease-in-out;
}

/* Assuming you have react-toastify installed, you can override default styles */
/* .Toastify__close-button {
  color: rgb(15, 6, 6);
} */

.Toastify__slide-enter {
  animation: slideIn 0.5s ease-out forwards;
}

.Toastify__slide-exit {
  animation: slideOut 0.5s ease-in forwards;
}

@keyframes slideIn {
  from {
    transform: translateX(100%);
    opacity: 0;
  }

  50% {
    transform: translateX(10%);
    opacity: 1;
  }

  to {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translateX(0);
    opacity: 1;
  }

  50% {
    transform: translateX(10%);
    opacity: 1;
  }

  to {
    transform: translateX(100%);
    opacity: 0;
  }
}


.Toastify__progress-bar--success {
  background: linear-gradient(91deg,
      #10d6e3 0%,
      #6265e8 49.42%,
      #fa93bf 97.86%) !important;
}


/* .leaflet-container {
  filter: grayscale(100%) contrast(120%) brightness(90%);

} */

.pulsing-icon {
  position: relative;
  /* background: red; */
  /* Adjust the color of the pulse */
  border-radius: 50%;
  animation: pulse-animation 1.5s infinite;
  box-shadow: 0 0 0 rgba(255, 0, 0, 0.4);
}

@keyframes pulse-animation {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  50% {
    transform: scale(1.5);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}


.leaflet-bottom {
  bottom: 0;
  display: none !important;
}

/* new css */

/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/

:root {
  scroll-behavior: smooth;
}

body,
html {
  overflow-x: hidden;
  background: rgba(245, 245, 247, 0.75);
  font-family: 'Lato';
}

p {
  font-weight: 400;
}

a {
  color: #243A73;
  text-decoration: none;
  transition: 0.5s all;
  font-family: 'Lato';
}

a:hover {
  color: #000000;
  text-decoration: none;
}

html,
body {
  scroll-behavior: smooth;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Lato';
}

/*--------------------------------------------------------------
# Fonts
--------------------------------------------------------------*/

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Italic.woff2') format('woff2'),
    url('../fonts/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Light.woff2') format('woff2'),
    url('../fonts/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-LightItalic.woff2') format('woff2'),
    url('../fonts/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-Hairline.woff2') format('woff2'),
    url('../fonts/Lato-Hairline.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Regular.woff2') format('woff2'),
    url('../fonts/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato Hairline';
  src: url('../fonts/Lato-HairlineItalic.woff2') format('woff2'),
    url('../fonts/Lato-HairlineItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BoldItalic.woff2') format('woff2'),
    url('../fonts/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Bold.woff2') format('woff2'),
    url('../fonts/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-Black.woff2') format('woff2'),
    url('../fonts/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato';
  src: url('../fonts/Lato-BlackItalic.woff2') format('woff2'),
    url('../fonts/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

.container-small {
  max-width: 900px;
  margin: 0 auto;
}

.container {
  max-width: 1170px;
  margin: 0 auto;
}

.container-fluid {
  max-width: 1300px;
  margin: 0 auto;
}

.header_main {
  border-bottom: 0.5px solid rgba(0, 0, 0, 0.1);
  background: rgba(245, 245, 247, 0.75);
  height: 88px;
  position: relative;
  display: flex;
  align-items: center;
}

.header_left {
  display: flex;
  align-items: center;
}

.header_menus ul {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  margin: 0;
  list-style: none;
}

.social_box {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.header_left a {
  display: block;
  margin-right: 40px;
}

.social_box .social_ico {
  display: block;
  margin-right: 18px;
}

.header_left a img {
  display: block;
  max-width: 52px;
}

.languagee .form-select {
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 56px;
  font-weight: 400;
  font-size: 17px;
  box-shadow: unset;
}

.header_menus ul li a {
  color: rgba(13, 13, 13, 1);
  font-size: 17px;
  font-weight: 400;
  display: block;
  transition: 0.3s ease-in-out;
  text-align: center;
}

.header_menus ul li a.active {
  font-weight: 900;
  color: rgba(13, 13, 13, 1);
}

.header_menus ul li {
  margin: 0 34px;
}

.header_dropdown .btn {
  background-color: transparent;
  border: 0;
  box-shadow: unset !important;
  outline: 0 !important;
  padding: 0 0;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0 !important;
}

.soonpart {
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.1);
  background: rgba(245, 245, 247, 0.75);
  padding: 8px 0;
}

.soonpart_content p {
  margin: 0;
  font-weight: 400;
  font-size: 17px;
}

.Become_Partner {
  background: rgba(250, 147, 191, 0.1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  height: 38px;
  color: #e084ab !important;
  border-radius: 50px;
  font-weight: 700;
  font-size: 17px;
  width: 192px;
}

.Become_Partner img {
  margin-left: 10px;
  max-height: 16px;
}

.banner_part {
  padding: 100px 0 180px;
  position: relative;
}

.banner_part::after {
  position: absolute;
  content: '';
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  background-image: url(../img/bg.svg);
  width: 940px;
  z-index: 0;
  height: 100%;
  background-repeat: no-repeat;
  background-position: center 25%;
  background-size: cover;
}

.banner_part .container {
  position: relative;
  z-index: 1;
}

.banner_content h2 {
  font-weight: 900;
  font-size: 80px;
  line-height: 86px;
  margin: 0 0 25px;
}

.banner_content p {
  color: rgba(77, 77, 77, 1);
  font-size: 20px;
  line-height: 32px;
  font-weight: 400;
  margin: 0;
  max-width: 290px;
}

.emailbox {
  margin: 50px 0 40px;
}

.emailbox .form-group .form-control {
  border: 1px solid rgba(13, 13, 13, 0.25);
  height: 60px;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 400;
  padding: 0px 30px 0px 32px;
  box-shadow: unset !important;
}

.getstarted {
  background: rgba(98, 101, 232, 1);
  color: #fff !important;
  height: 60px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  font-size: 20px;
  font-weight: 900;
  padding: 0 36px;
  position: absolute;
  right: 0;
  top: 0;
  box-shadow: -2px -2px 8px 0px rgba(16, 18, 111, 0.4) inset;
}

.download_btn a {
  margin-right: 30px;
  display: inline-block;
}

.banner_img img {
  max-width: 85%;
  margin-bottom: -65px;
}

.footer_main {
  background: rgba(13, 13, 13, 1);
  padding: 96px 0;
  position: relative;
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.footer_about .logo {
  display: block;
  margin-bottom: 50px;
}

.footer_about .logo img {
  max-width: 215px;
}

.footer_btn {
  background-color: rgba(98, 101, 232, 1);
  color: #fff !important;
  font-weight: 700;
  font-size: 18px;
  height: 48px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  padding: 0 46px;
}

.footer_menus h2 {
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  line-height: 14px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
}

.footer_newsletter h2 {
  color: #fff;
  font-weight: 900;
  font-size: 18px;
  line-height: 14px;
  letter-spacing: 0.2px;
  margin-bottom: 40px;
}

.footer_menus ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.footer_menus ul li a {
  color: #fff ! IMPORTANT;
  font-weight: 400;
  font-size: 14px;
  display: inline-block;
  transition: 0.3s all;
}

.footer_menus ul li a:hover {
  transform: translateX(5px);
}

.footer_menus ul li {
  margin-bottom: 22px;
}

.newsletter_box .form-control {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: transparent;
  border-radius: 50px;
  height: 42px;
  box-shadow: unset;
  padding: 0px 0px 0px 24px;
  color: rgba(255, 255, 255, 1);
}

.newsletter_box button {
  background-color: #fff;
  border: 0;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 900;
  color: rgba(13, 13, 13, 1);
  height: 42px;
  padding: 0 25px;
  position: absolute;
  right: 0;
  top: 0;
}

.appdownload {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;
}

.appdownload a img {
  max-width: 100%;
}

.appdownload a {
  width: 44%;
}

.copyright {
  background: rgba(13, 13, 13, 1);
  padding: 30px 0;
}

.copyright_data {
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.copyright_links,
.copyright_links a {
  color: rgba(255, 255, 255, 1);
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
}

.social_footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.social_footer a {
  margin: 0 8px;
}

.social_footer a img {
  transition: 0.3s ease-in-out;
}

.social_footer a:hover img {
  transform: translateY(-5px);
}

.copyright_select .form-select {
  border-radius: 50px;
  background-color: transparent;
  color: #fff;
  font-size: 17px;
  font-weight: 700;
  border: 2px solid #fff;
  padding: 0 24px 0 24px;
  height: 48px;
  box-shadow: unset;
  background-image: url(../img/expand_more.png);
  background-size: 13px;
  background-repeat: no-repeat;
  background-position: 130px center;
}

.captain_slider .captainslider_box.slick-slide.slick-current.slick-active.slick-center {
  transform: scale(1);
}

.captainslider_box {
  transform: scale(0.9);
  height: 500px;
  overflow: hidden;
  border-radius: 30px;
  transition: 0.5s all;
  position: relative;
}

.captainslider_box::after {
  position: absolute;
  content: '';
  bottom: -80px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 84.9%);
  height: 100%;
  width: 100%;
}

.captainslider_box img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.captain_slider {
  padding: 50px 0 80px;
}

.captainslider_content {
  position: absolute;
  bottom: 40px;
  left: 40px;
  z-index: 1;
}

.captainslider_content h2 {
  font-weight: 700;
  font-size: 44px;
  line-height: 55px;
  margin: 0;
  color: rgba(255, 255, 255, 0.9);
}

.captainslider_content p {
  font-size: 27px;
  font-weight: 400;
  color: #fff;
  margin: 0;
}

.commanservices {
  padding: 60px 0;
}

.comman_head h2 {
  color: rgba(13, 13, 13, 1);
  font-size: 50px;
  font-weight: 700;
  margin: 0;
  line-height: 50px;
}

.comman_head {
  margin-bottom: 60px;
}

.commanservices_box {
  text-align: center;
  padding: 0 40px;
}

.commanservices_box img {
  max-height: 81px;
}

.commanservices_box h3 {
  color: rgba(13, 13, 13, 1);
  font-size: 32px;
  font-weight: 700;
  line-height: 24px;
  margin: 30px 0 30px;
}

.commanservices_box p {
  color: rgba(13, 13, 13, 1);
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.captain_slider .slick-next.slick-arrow {
  background-image: url(../img/expand_circle_right.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: 48%;
  z-index: 1;
  transform: translateY(-50%);
  right: 20px;
}

.captain_slider .slick-prev.slick-arrow {
  background-image: url(../img/expand_circle_right.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: 48%;
  z-index: 1;
  transform: scalex(-1) translateY(-50%);
  left: 20px;
}

.howitworkss_box {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 40px;
  margin: 0px 0 200px;
  position: relative;
  align-items: center;
  height: 720px;
  background-size: cover;
}

.howitworkss {
  padding: 100px 0 180px;
}

.howitworkss .container-fluid .howitworkss_box:last-child {
  margin-bottom: 0;
}

.howitworkss .container-fluid .howitworkss_box:nth-child(even) .howitworkss_content {
  padding: 0 100px 0 0;
}

.howitworkss_content span {
  font-size: 27px;
  font-weight: 700;
  line-height: 20px;
  color: #fff;
  display: block;
}

.howitworkss_content h2 {
  font-size: 50px;
  font-weight: 900;
  line-height: 62px;
  color: #fff;
  display: block;
  margin: 20px 0 20px;
}

.howitworkss_content h2 strong {
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.howitworkss_content p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
  line-height: 34px;
  margin: 0;
}

.howitworkss_content {
  padding: 0 0 0 100px;
}

.howitworkss_img {
  margin: -60px 0;
}

.howitworkss_img img {
  max-width: 100%;
  /* box-shadow: 4px 4px 16px 0px rgba(0, 0, 0, 0.25); */
  border-radius: 70px;
}

.downloadapotholis {
  padding: 100px 0;
}

.downloadapotholis_img img {
  max-width: 100%;
}

.apotholis_content h2 {
  font-weight: 900;
  font-size: 50px;
  line-height: 62px;
  color: rgba(0, 0, 0, 0.75);
  margin: 0 0 30px;
}

.apotholis_content h2 span {
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.apotholis_content p {
  font-size: 20px;
  font-weight: 400;
  line-height: 32px;
  color: rgba(26, 15, 28, 1);
}

.download_atho {
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  border-radius: 12px;
  background-color: rgba(98, 101, 232, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #fff !important;
  padding: 0 40px;
}

.commanhead1 span {
  font-size: 20px;
  font-weight: 400;
  color: rgba(26, 15, 28, 1);
  display: block;
}

.commanhead1 h2 {
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 900;
  font-size: 63px;
  line-height: 75px;
  margin: 0;
  text-transform: uppercase;
}

.commanhead1 {
  margin-bottom: 60px;
}

.map_box img {
  max-width: 100%;
  width: 100%;
}

.app_screen {
  padding: 100px 0 100px;
}

.check_img {
  max-width: 35px;
}

.commaper_with tr th {
  text-align: center;
  border: 0;
  font-size: 20px;
  font-weight: 700;
  color: rgba(13, 13, 13, 1);
  padding: 24px 20px;
}

.commaper_with tr td {
  text-align: center;
  font-size: 20px;
  font-weight: 700;
  color: rgba(13, 13, 13, 1);
  padding: 24px 20px;
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
  border: 0;
}

.commaper_with .table tbody tr td:first-child {
  text-align: left;
}

.table>:not(:first-child) {
  border-top: 0;
}

.commaper_with tr td p {
  font-size: 16px;
  margin: 20px 0 0;
  max-width: 280px;
}

.appscreen_img {
  transform: scale(0.9);
  transition: 0.3s all;
  opacity: 0.2;
  text-align: center;
}

.app_screen_slider .appscreen_img.slick-slide.slick-current.slick-active.slick-center {
  transform: scale(1);
  opacity: 1;
}

.commaper_with {
  margin-top: 60px;
}

.appscreen_img img {
  height: 486px;
  margin: 0 auto;
}

.settingss {
  text-align: center;
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  font-size: 20px;
  font-weight: 400;
  color: rgba(13, 13, 13, 1);
  height: 48px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 50px;
  padding: 0 22px;
  margin-bottom: 40px;
}

.app_screen_slider .slick-prev.slick-arrow {
  background-image: url(../img/slider_left.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: -65px;
  z-index: 1;
  left: 38%;
}

.app_screen_slider .slick-next.slick-arrow {
  background-image: url(../img/slider_right.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: -65px;
  z-index: 1;
  right: 38%;
}

.cumminity_box {
  height: 490px;
  position: relative;
  display: flex;
  align-items: center;
}

.cumminity_slider {
  background-color: rgba(98, 101, 232, 1);
  padding: 0 100px 0 30px;
}

.cumminity_box span {
  font-weight: 700;
  color: #fff;
  font-size: 32px;
  display: block;
  line-height: 30px;
}

.cumminity_box h2 {
  font-weight: 700;
  font-size: 42px;
  color: #fff;
  line-height: 52px;
  margin: 26px 0 20px;
}

.cumminity_box p {
  font-weight: 400;
  font-size: 20px;
  color: #fff;
}

.cumminity_slider .slick-prev.slick-arrow {
  display: none !important;
}

.cumminity_slider .slick-next.slick-arrow {
  background-image: url(../img/arrow_forward_ios.png);
  border: 0;
  background-repeat: no-repeat;
  background-position: center;
  height: 39px;
  width: 25px;
  border-radius: 50px;
  text-indent: -999999999999999px;
  position: absolute;
  top: 50%;
  z-index: 1;
  right: 40px;
  background-color: transparent;
  transform: translateY(-50%);
}

.cumminity_img {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 490px;
}

.get_app h2 span {
  color: rgba(250, 147, 191, 1);
  background: unset;
  -webkit-text-fill-color: unset;
}

.get_app .download_atho {
  background-color: rgba(250, 147, 191, 1);
}

.download_athoborder {
  font-size: 18px;
  font-weight: 700;
  height: 60px;
  border-radius: 12px;
  border: 2px solid rgba(250, 147, 191, 1);
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: rgba(250, 147, 191, 1) !important;
  padding: 0 40px;
  background-color: #ffff;
}

.allview .nav-tabs {
  border-radius: 15px;
  justify-content: center;
  display: inline-flex;
  border: 0;
  background: rgba(118, 118, 128, 0.12);
  height: 48px;
  align-items: center;
  padding: 0 15px;
  margin-bottom: 80px;
}

.allview .nav-tabs .nav-link {
  width: 56px;
  display: flex;
  align-items: center;
  padding: 0;
  justify-content: center;
  height: 38px;
  border: 0 !important;
  box-shadow: unset;
  border-radius: 10px;
  margin: 0;
}

.allview .nav-tabs .nav-link img {
  max-width: 30px;
}

.allview .nav-tabs .nav-link.active {
  box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15) !important;
  background-color: #fff;
}

.allview {
  padding: 100px 0;
}

.Partner_banner {
  position: relative;
  padding: 100px 0 320px;
}

.Partnerbanner_content {
  text-align: center;
  position: relative;
  z-index: 1;
}

.Partnerbanner_content h1 {
  font-size: 80px;
  font-weight: 900;
  line-height: 88px;
  margin-bottom: 20px;
}

.Partnerbanner_content h1 span {
  color: rgba(250, 147, 191, 1);
}

.Partnerbanner_content p {
  font-size: 20px;
  font-weight: 400;
  color: rgba(13, 13, 13, 1);
  margin: 0;
  line-height: 32px;
}

.Partnerbanner_content .getstarted {
  box-shadow: -2px -2px 8px 0px rgba(135, 0, 57, 0.4) inset;
  background: linear-gradient(93.53deg, #FA93BF -29.92%, #D5538F 104.09%);
  font-size: 16px;
  padding: 0 26px;
  height: 50px;
}

.Partnerbanner_content .emailbox .form-group .form-control {
  border: 1px solid rgba(13, 13, 13, 0.25);
  height: 50px;
  font-size: 16px;
  padding: 0px 30px 1px 26px;
  line-height: normal;
}

.cut_img_1 {
  position: absolute;
  top: 130px;
  left: 0;
  max-width: 100%;
}

.cut_img_2 {
  position: absolute;
  top: -80px;
  right: 0px;
  max-width: 100%;
}

/* .Early_access {
  display: inline-flex;
  align-items: center;
  justify-content: center; 
  background: rgba(255, 255, 255, 1);
  border-radius: 50px;
  height: 42px;
  width: 128px; 
  overflow: hidden;
  position: relative;
}
.Early_access::after{
  position: absolute;
  content: '';
  border: 2px solid transparent;
  border-image: linear-gradient(77.65deg, #FA93BF 1.62%, #6265E8 54.14%, #10D6E3 98.88%);
  border-image-slice: 1; 
  width: 100%;
  height: 100%;
}
.Early_access span{
  background: linear-gradient(90.6deg, #E68DC5 -5.52%, #6068E8 50.6%, #22BEE5 90.49%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 14px;
  font-weight: 900; 
} */
.Early_access {
  margin-bottom: 25px;
}

.Partner_banner::after {
  position: absolute;
  content: '';
  background-image: url(../img/square.png);
  height: 100%;
  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
  z-index: 0;
}

.partnermain {
  position: relative;
  z-index: 1;
  margin-top: -180px;
  margin-bottom: 130px;
}

.partner_boxes {
  box-shadow: 0px 12px 32px 0px rgba(250, 147, 191, 0.25);
  background-color: #fff;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
}

.partner_uperheding {
  font-weight: 700;
  font-size: 16px;
  color: rgba(13, 13, 13, 1);
  line-height: 12px;
}

.partner_uperheding {
  padding: 25px 0 55px;
  text-align: center;
}

.full_box {
  height: 282px;
}

.track_box {
  height: 133px;
}

.overview_img {
  position: absolute;
  right: 0;
  bottom: 0;
  max-width: 100%;
}

.small_boxess {
  height: 133px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.small_head {
  font-weight: 700;
  font-size: 16px;
  color: rgba(13, 13, 13, 1);
  line-height: 12px;
  text-align: center;
}

.small_icons {
  margin-bottom: 15px;
  max-width: 50px;
}

.bussiness_report {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.reviewimg {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.calender_img {
  max-width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.partneradjust {
  margin-top: -148px;
}

.mobile_menuss {
  display: none;
}

.mobile_menuss .btn {
  background: transparent;
  border: 0;
  padding: 0;
  box-shadow: unset;
}

.mobile_menuss .btn img {
  max-width: 31px;
}

.captainslider_box video {
  height: 100%;
  width: 100%;
}

.contact_inner {
  background: #6265E8;
  border-radius: 40px;
  padding: 60px 40px 150px;
}

.contact_content h1 {
  font-family: Lato;
  font-size: 42px;
  font-weight: 700;
  line-height: 56px;
  color: #fff;
  margin-bottom: 12px;
}

.contact_content p {
  font-family: Lato;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: #fff;
}

.form_design .form-control {
  font-family: Lato;
  font-size: 14px;
  font-weight: 500;
  line-height: 26px;
  color: #fff;
  height: 42px;
  border-bottom: 1px solid #FFFFFF !important;
  border-radius: 0;
  border: 0;
  background-color: transparent !important;
  box-shadow: unset !important;
  padding: 0;
}

.submit_btn {
  height: 52px;
  border: 0;
  background-color: #fff;
  border-radius: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #6265E8;
  font-size: 22px;
  font-weight: 500;
  line-height: 28.8px;
}

.contact_content img {
  margin-top: 60px;
  margin-left: 40px;
  max-width: 100%;
}

.form_design .form-control::placeholder {
  color: #fff;
}

.form_design .form-group {
  margin-bottom: 35px;
}

.form_design .form-control::-webkit-scrollbar {
  display: none !important;
}

.custom_check label {
  position: relative;
  padding-left: 30px;
  display: inline-block;
  font-size: 20px;
  font-weight: 400;
  line-height: 26px;
  color: #fff;
  margin: 0;
}

.custom_check label::after {
  position: absolute;
  content: '';
  top: 4px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 2px solid #FFFFFF;
}

.custom_check label::before {
  position: absolute;
  content: '';
  top: 9px;
  left: 5px;
  height: 10px;
  width: 10px;
  background-color: #fff;
  transition: 0.5s all;
  transform: scale(0);
}

.custom_check input:checked~label::before {
  transform: scale(1);
}

@media (max-width:1279px) {
  .header_menus ul li {
    margin: 0 24px;
  }

  .container-fluid {
    max-width: 1160px;
  }
}

@media (max-width:1199px) {
  .container-fluid {
    max-width: 96%;
  }

  .header_left a {
    margin-right: 30px;
  }

  .header_menus ul li a {
    font-size: 16px;
  }

  .header_menus ul li {
    margin: 0 20px;
  }

  .header_left a img {
    max-width: 42px;
  }

  .social_box .social_ico {
    margin-right: 14px;
  }

  .social_box .social_ico img {
    max-width: 28px;
  }

  .header_dropdown .btn {
    height: 28px;
    width: 28px;
  }

  .header_main {
    height: 70px;
  }

  .soonpart_content p {
    font-size: 16px;
  }

  .Become_Partner {
    font-size: 16px;
    width: 182px;
  }

  .container {
    max-width: 95%;
    margin: 0 auto;
  }

  .banner_content h2 {
    font-size: 60px;
    line-height: 66px;
    margin: 0px 0 22px;
  }

  .banner_content p {
    font-size: 18px;
    line-height: 28px;
    max-width: 290px;
  }

  .emailbox {
    margin: 40px 0 30px;
  }

  .emailbox .form-group .form-control {
    height: 54px;
    font-size: 16px;
    padding: 0px 30px 0px 28px;
  }

  .getstarted {
    height: 54px;
    border-radius: 60px;
    font-size: 18px;
    font-weight: 900;
    padding: 0 28px;
  }

  .banner_img img {
    max-width: 80%;
    margin-bottom: -45px;
  }

  .banner_part {
    padding: 70px 0 140px;
  }

  .banner_part::after {
    width: 700px;
  }

  .captainslider_box {
    height: 400px;
    border-radius: 20px;
  }

  .captainslider_content h2 {
    font-size: 36px;
    line-height: 44px;
  }

  .captainslider_content p {
    font-size: 22px;
  }

  .captainslider_content {
    bottom: 30px;
    left: 30px;
  }

  .captain_slider {
    padding: 30px 0 60px;
  }

  .commanservices {
    padding: 50px 0;
  }

  .comman_head h2 {
    font-size: 38px;
  }

  .comman_head {
    margin-bottom: 50px;
  }

  .commanservices_box img {
    max-height: 66px;
  }

  .commanservices_box {
    padding: 0 30px;
  }

  .commanservices_box p {
    font-size: 16px;
    line-height: 24px;
    margin: 0;
  }

  .commanservices_box h3 {
    font-size: 28px;
    line-height: 20px;
    margin: 25px 0 25px;
  }

  .howitworkss_content {
    padding: 0 0 0 60px;
  }

  .howitworkss_content span {
    font-size: 22px;
    line-height: 19px;
  }

  .howitworkss_content h2 {
    font-size: 38px;
    line-height: 51px;
    margin: 14px 0 14px;
  }

  .howitworkss_content p {
    font-size: 18px;
    line-height: 32px;
    margin: 0;
  }

  .howitworkss_box {
    border-radius: 30px;
    margin: 0px 0 140px;
    height: 600px;
  }

  .howitworkss_img img {
    max-width: 70%;
    border-radius: 50px;
  }

  .howitworkss {
    padding: 100px 0 130px;
  }

  .cumminity_img img {
    max-width: 80%;
  }

  .cumminity_box {
    height: 440px;
  }

  .cumminity_box span {
    font-size: 24px;
    line-height: 20px;
  }

  .cumminity_box h2 {
    font-size: 38px;
    line-height: 52px;
    margin: 20px 0 20px;
  }

  .cumminity_box p {
    font-size: 18px;
    margin: 0;
  }

  .cumminity_img {
    height: 440px;
  }

  .appscreen_img img {
    height: 380px;
    max-width: 100%;
  }

  .commanhead1 span {
    font-size: 18px;
  }

  .commanhead1 h2 {
    font-size: 50px;
    line-height: 65px;
  }

  .commanhead1 {
    margin-bottom: 40px;
  }

  .apotholis_content h2 {
    font-size: 40px;
    line-height: 54px;
    margin: 0 0 18px;
  }

  .apotholis_content p {
    font-size: 18px;
    line-height: 30px;
  }

  .download_atho,
  .download_athoborder {
    font-size: 16px;
    height: 54px;
    padding: 0 20px;
  }

  .footer_main {
    padding: 70px 0;
  }

  .footer_about .logo {
    margin-bottom: 30px;
  }

  .footer_about .logo img {
    max-width: 190px;
  }

  .footer_btn {
    font-size: 16px;
    height: 46px;
    padding: 0 40px;
  }

  .howitworkss .container-fluid .howitworkss_box:nth-child(even) .howitworkss_content {
    padding: 0 70px 0 0;
  }

  .settingss {
    font-size: 18px;
    height: 46px;
  }

  .Partnerbanner_content h1 {
    font-size: 60px;
    font-weight: 900;
    line-height: 68px;
    margin-bottom: 16px;
  }

  .Partnerbanner_content p {
    font-size: 18px;
  }

  .Partner_banner {
    padding: 70px 0 290px;
  }

  .cut_img_1 {
    top: 60px;
  }

  .cut_img_2 {
    top: -150px;
  }

  .partnermain {
    margin-top: -160px;
    margin-bottom: 90px;
  }

  .allview {
    padding: 80px 0;
  }

  .allview .nav-tabs {
    margin-bottom: 50px;
  }
}

@media (max-width:1023px) {
  .container-fluid {
    max-width: 98%;
  }

  .container {
    max-width: 98%;
  }

  .container-small {
    max-width: 92%;
  }

  .banner_content h2 {
    font-size: 48px;
    line-height: 56px;
    margin: 0px 0 20px;
  }

  .banner_content p {
    font-size: 17px;
    line-height: 27px;
  }

  .emailbox .form-group .form-control {
    height: 48px;
    font-size: 15px;
    padding: 0px 30px 0px 22px;
  }

  .emailbox {
    margin: 25px 0 25px;
  }

  .getstarted {
    height: 48px;
    border-radius: 60px;
    font-size: 14px;
    font-weight: 800;
    padding: 0 22px;
  }

  .download_btn {
    display: flex;
  }

  .download_btn a {
    margin-right: 18px;
  }

  .download_btn a img {
    max-height: 40px;
  }

  .banner_img img {
    max-width: 80%;
    margin-bottom: -25px;
  }

  .banner_part {
    padding: 60px 0 80px;
  }

  .banner_part::after {
    width: 550px;
  }

  .captainslider_box {
    height: 300px;
    border-radius: 14px;
  }

  .captainslider_content h2 {
    font-size: 30px;
    line-height: 38px;
  }

  .captainslider_content p {
    font-size: 18px;
  }

  .captainslider_content {
    bottom: 20px;
    left: 20px;
  }

  .captain_slider {
    padding: 30px 0 50px;
  }

  .comman_head h2 {
    font-size: 30px;
  }

  .commanservices_box {
    padding: 0;
  }

  .commanservices_box img {
    max-height: 52px;
  }

  .commanservices_box h3 {
    font-size: 22px;
    line-height: 18px;
    margin: 20px 0 20px;
  }

  .howitworkss {
    padding: 60px 0 100px;
  }

  .howitworkss_content h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 12px 0 12px;
  }

  .howitworkss_content span {
    font-size: 18px;
    line-height: 16px;
  }

  .howitworkss_content p {
    font-size: 16px;
    line-height: 28px;
    margin: 0;
  }

  .howitworkss_content {
    padding: 0 0 0 30px;
  }

  .howitworkss_box {
    border-radius: 20px;
    margin: 0px 0 100px;
    height: 450px;
  }

  .howitworkss_img img {
    max-width: 70%;
    border-radius: 40px;
  }

  .cumminity_box h2 {
    font-size: 30px;
    line-height: 42px;
    margin: 15px 0 15px;
  }

  .cumminity_box span {
    font-size: 20px;
    line-height: 13px;
  }

  .cumminity_box p {
    font-size: 16px;
    margin: 0;
  }

  .cumminity_slider .slick-next.slick-arrow {
    height: 29px;
    width: 15px;
    top: 50%;
    right: 20px;
    background-size: 15px;
  }

  .cumminity_box,
  .cumminity_img {
    height: 340px;
  }

  .cumminity_slider {
    padding: 0 70px 0 30px;
  }

  .app_screen {
    padding: 70px 0 70px;
  }

  .settingss {
    font-size: 16px;
    height: 43px;
  }

  .app_screen_slider .slick-next.slick-arrow {
    top: -65px;
    right: 36%;
  }

  .app_screen_slider .slick-prev.slick-arrow {
    top: -65px;
    left: 36%;
  }

  .appscreen_img img {
    height: 400px;
    max-width: 100%;
  }

  .commaper_with {
    margin-top: 40px;
  }

  .commaper_with tr th,
  .commaper_with tr td {
    font-size: 16px;
    padding: 14px 10px;
  }

  .check_img {
    max-width: 26px;
  }

  .commanhead1 span {
    font-size: 16px;
  }

  .commanhead1 h2 {
    font-size: 38px;
    line-height: 55px;
  }

  .commanhead1 {
    margin-bottom: 30px;
  }

  .downloadapotholis {
    padding: 60px 0;
  }

  .apotholis_content h2 {
    font-size: 30px;
    line-height: 40px;
    margin: 0 0 10px;
  }

  .apotholis_content p {
    font-size: 16px;
    line-height: 28px;
  }

  .download_atho,
  .download_athoborder {
    font-size: 14px;
    height: 46px;
    padding: 0 12px;
  }

  .footer_main {
    padding: 50px 0;
  }

  .footer_about {
    text-align: center;
  }

  .footer_menus {
    text-align: center;
  }

  .footer_menus h2,
  .footer_newsletter h2 {
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.2px;
    margin-bottom: 30px;
  }

  .footer_main .container>.row,
  .copyright .container>.row {
    justify-content: center;
  }

  .appdownload a {
    width: 48%;
  }

  .appdownload {
    margin-top: 28px;
  }

  .footer_newsletter {
    text-align: center;
  }

  .footer_menus ul li {
    margin-bottom: 16px;
  }

  .social_box {
    display: none;
  }

  .mobile_menuss {
    display: block;
  }

  .header_menus ul {
    flex-wrap: wrap;
  }

  .header_menus ul li {
    margin: 0 0 20px;
    width: 100%;
    text-align: left;
  }

  .header_menus ul li a {
    font-size: 18px;
    text-align: left;
  }

  .mobile_menuss .social_box {
    display: flex;
    justify-content: center;
  }

  .Early_access img {
    max-width: 110px;
  }

  .Early_access {
    margin-bottom: 20px;
  }

  .Partnerbanner_content h1 {
    font-size: 48px;
    line-height: 58px;
    margin-bottom: 14px;
  }

  .Partnerbanner_content p {
    font-size: 16px;
    line-height: 26px;
  }

  .cut_img_1 {
    top: 30px;
    max-width: 160px;
  }

  .cut_img_2 {
    top: -130px;
    max-width: 230px;
  }

  .Partner_banner {
    padding: 50px 0 170px;
  }

  .small_head {
    font-size: 15px;
    line-height: 21px;
  }

  .small_icons {
    margin-bottom: 10px;
    max-width: 40px;
  }

  .full_box {
    height: 252px;
  }

  .small_boxess,
  .track_box {
    height: 116px;
  }

  .partner_uperheding {
    padding: 20px 0 35px;
    text-align: center;
    font-size: 15px;
  }

  .partneradjust {
    margin-top: -134px;
  }

  .partnermain {
    margin-top: -90px;
    margin-bottom: 30px;
  }

  .allview {
    padding: 60px 0;
  }

  .header_main {
    z-index: 999;
  }

  .contact_inner {
    border-radius: 30px;
    padding: 40px 20px 58px;
    margin: 0;
  }

  .contact_content h1 {
    font-size: 29px;
    line-height: 41px;
    margin-bottom: 10px;
  }

  .contact_content img {
    margin-top: 40px;
    margin-left: 0;
    display: none;
  }
}

@media (max-width:767px) {
  .header_left {
    justify-content: space-between;
  }

  .languagee .form-select {
    font-weight: 600;
    font-size: 15px;
  }

  .header_menus ul li a {
    font-size: 16px;
    text-align: left;
  }

  .header_menus ul li {
    margin: 0px 0 16px;
  }

  .header_main {
    height: 60px;
  }

  .soonpart {
    padding: 10px 0;
  }

  .Become_Partner {
    font-size: 14px;
    width: 162px;
    height: 34px;
  }

  .Become_Partner img {
    margin-left: 6px;
    max-height: 12px;
    margin-bottom: -2px;
  }

  .banner_img img {
    max-width: 100%;
    margin-bottom: 0;
  }

  .banner_img {
    margin-bottom: 30px;
  }

  .banner_content {
    text-align: center;
  }

  .banner_content h2 {
    font-size: 36px;
    line-height: 44px;
    margin: 0px 0 15px;
  }

  .banner_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .download_btn {
    display: flex;
    justify-content: center;
    text-align: center;
  }

  .download_btn a {
    margin: 0 5px;
  }

  .banner_part::after {
    display: none;
  }

  .banner_part {
    padding: 50px 0 40px;
  }

  .captain_slider {
    padding: 20px 0 20px;
  }

  .commanservices {
    padding: 40px 0;
  }

  .captainslider_box {
    height: 240px;
    border-radius: 10px;
  }

  .captainslider_content {
    bottom: 10px;
    left: 0;
    right: 0;
    margin: 0 auto;
    text-align: center;
  }

  .captainslider_content p {
    font-size: 16px;
  }

  .captainslider_content h2 {
    font-size: 24px;
    line-height: 31px;
  }

  .comman_head h2 {
    font-size: 26px;
    line-height: 30px;
  }

  .comman_head {
    margin-bottom: 30px;
  }

  .commanservices_box {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .commanservices_box h3 {
    font-size: 18px;
    line-height: 16px;
    margin: 14px 0 14px;
  }

  .commanservices_box img {
    max-height: 40px;
  }

  .commanservices_box p {
    font-size: 15px;
    line-height: 24px;
    margin: 0;
  }

  .howitworkss {
    padding: 0 0 40px;
  }

  .howitworkss_content h2 {
    font-size: 26px;
    line-height: 36px;
    margin: 10px 0 10px;
  }

  .howitworkss_content p {
    font-size: 15px;
    line-height: 26px;
    margin: 0;
  }

  .howitworkss_content span {
    font-size: 16px;
    line-height: 14px;
  }

  .howitworkss_content {
    padding: 0 20px !important;
    text-align: center;
  }

  .howitworkss_box {
    margin: 0px 0 30px;
    height: auto;
    padding: 40px 0 30px;
    border-radius: 20px;
    background-position: center;
  }

  .howitworkss_img {
    margin: 0 0 30px;
  }

  .order-changed {
    order: -1;
  }

  .howitworkss_img img {
    max-width: 90%;
    border-radius: 40px;
  }

  .cumminity_box,
  .cumminity_img {
    height: auto;
    padding: 40px 0;
  }

  .cumminity_box span {
    font-size: 16px;
    line-height: 13px;
  }

  .cumminity_box h2 {
    font-size: 26px;
    line-height: 34px;
    margin: 10px 0 10px;
  }

  .cumminity_box p {
    font-size: 15px;
    margin: 0;
  }

  .cumminity_slider {
    padding: 0 50px 0 30px;
  }

  .app_screen {
    padding: 50px 0 50px;
  }

  .settingss {
    font-size: 14px;
    height: 36px;
  }

  .app_screen_slider .slick-prev.slick-arrow {
    top: -60px;
    left: 15%;
    height: 30px;
    width: 30px;
  }

  .app_screen_slider .slick-next.slick-arrow {
    top: -60px;
    right: 15%;
    height: 30px;
    width: 30px;
  }

  .commaper_with tr th,
  .commaper_with tr td {
    font-size: 14px;
    padding: 12px 8px;
    white-space: nowrap;
  }

  .commaper_with tr td p {
    font-size: 15px;
    margin: 10px 0 0;
    max-width: 200px;
    white-space: break-spaces;
  }

  .check_img {
    max-width: 20px;
  }

  .commanhead1 span {
    font-size: 14px;
  }

  .commanhead1 h2 {
    font-size: 28px;
    line-height: 45px;
  }

  .downloadapotholis {
    padding: 50px 0;
  }

  .apotholis_content h2 {
    font-size: 26px;
    line-height: 34px;
    margin: 0 0 10px;
  }

  .apotholis_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .download_atho {
    font-size: 15px;
    height: 46px;
    padding: 0 20px;
  }

  .appscreen_img {
    transform: scale(1);
  }

  .appscreen_img img {
    max-width: 100%;
    height: auto;
  }

  .footer_about .logo {
    margin-bottom: 20px;
  }

  .footer_btn {
    font-size: 15px;
    height: 38px;
    padding: 0 24px;
  }

  .footer_about {
    text-align: center;
    margin-bottom: 20px;
  }

  .footer_menus h2,
  .footer_newsletter h2 {
    font-size: 16px;
    line-height: 14px;
    letter-spacing: 0.2px;
    margin-bottom: 20px;
  }

  .copyright .container>.row {
    margin: 0;
  }

  .footer_main {
    padding: 30px 0;
  }

  .social_footer img {
    max-width: 30px;
  }

  .copyright_select .form-select {
    font-size: 15px;
    border: 1px solid #fff;
    padding: 0 14px 0 24px;
    height: 42px;
    background-size: 12px;
    background-position: 230px center;
  }

  .copyright_data,
  .copyright_links,
  .copyright_links a {
    font-size: 13px;
    line-height: 20px;
    text-align: center;
  }

  .cut_img_1 {
    top: 10px;
    max-width: 50px;
  }

  .cut_img_2 {
    top: -50px;
    max-width: 90px;
  }

  .Early_access img {
    max-width: 100px;
  }

  .Early_access {
    margin-bottom: 15px;
  }

  .Partnerbanner_content h1 {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 11px;
  }

  .Partnerbanner_content p {
    font-size: 15px;
    line-height: 25px;
  }

  .Partner_banner {
    padding: 50px 0 50px;
  }

  .partnermain {
    margin: 0 0 0px;
  }

  .partneradjust {
    margin-top: 0;
  }

  .track_box {
    height: 120px;
  }

  .allview {
    padding: 30px 0;
  }

  .allview .nav-tabs {
    margin-bottom: 30px;
  }

  .small_boxess {
    height: auto;
    padding: 20px 20px;
  }

  .get_app .row .col-md-6:first-child {
    order: 1;
    margin-top: 15px;
  }

  .apotholis_content p br {
    display: none;
  }

  .get_app .download_atho {
    display: flex;
    margin-bottom: 12px;
  }

  .download_athoborder {
    display: flex;
    margin: 0 !important;
  }

  .contact_inner {
    border-radius: 10px;
    padding: 30px 6px 18px;
    margin: 0;
  }

  .contact_content h1 {
    font-size: 22px;
    line-height: 32px;
    margin-bottom: 7px;
  }

  .contact_content p {
    font-size: 14px;
    line-height: 20px;
  }

  .form_design .form-group {
    margin-bottom: 20px;
  }

  .custom_check label {
    padding-left: 30px;
    font-size: 15px;
    line-height: 26px;
    margin: 0;
  }

  .submit_btn {
    height: 46px;
    border: 0;
    font-size: 18px;
    line-height: 26.8px;
  }
}

.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: .01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px !important;
  margin-left: 0;
  background: #FFFFFF;
  /* border: 1px solid #CACACA; */
  border-radius: 0px !important;
  line-height: 25px;
  /* height: 35px; */
  /* width: 300px; */
  outline: none;
}

.react-tel-input .form-control {

  width: -webkit-fill-available !important;
  outline: none;
}

/* input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 1000px #6265E8 inset !important;
  -webkit-text-fill-color: #000 !important;
} */

/* .scroll_buttons {
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  flex-direction: column;
}

.scroll_buttons button {
  margin: 5px 0;
  cursor: pointer;
} */